<template>
  <div class="container" style="padding: 20px; background-color: #e6e6e6">
    <h2>
      <b>
        Das Bewerberportal für die Aufnahme eines Studiums im Sommersemester 2025 an der TU Clausthal
        wird zu einem späteren Zeitpunkt freigeschaltet. Wenn Sie im Sommersemester 2025 mit dem
        Fachstudium beginnen möchten, nutzen Sie dafür nach der Freischaltung die
        <a href="https://tuc.hispro.de/" target="_blank">Online Bewerbung.</a>
      </b>
    </h2>
    <br />
    <h2>
      <b>
        Wenn Sie sich nicht in Deutschland aufhalten und ein Visum beantragen müssen, können Sie sich hier
        registrieren. Die Registrierung dient dazu, Informationen vorab an das Internationale Zentrum
        Clausthal zu übermitteln, damit Sie bei einer möglichen Zulassung ggf. Zeit für die Beantragung des
        Visums gewinnen. Führen Sie die Registrierung nur dann durch, wenn Sie die sprachlichen
        Anforderungen für eine Immatrikulation <u>nicht</u> erfüllt haben.
      </b>
    </h2>
    <br />
    <h2>
      <b>
        Please don’t use this portal to apply for the English taught programs.
      </b>
    </h2>
    <div class="field is-grouped">
      <div class="buttons">
        <div class="control">
          <button class="button is-link" v-on:click="next">
            Registrierung starten
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Start",

  methods: {
    next: function () {
      this.$emit("interface", "HZB");
    },
  },
};
</script>
