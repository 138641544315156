import Vue from "vue";
import App from "./App.vue";
import Vuex from "vuex";
import Vuelidate from "vuelidate";
import VueMaterial from 'vue-material'
import 'vue-material/dist/vue-material.min.css'
import 'vue-material/dist/theme/default.css'

Vue.use(VueMaterial)
Vue.use(Vuelidate);
Vue.use(Vuex);
Vue.config.productionTip = false;
//Vue.material.locale.dateFormat = 'dd.MM.yyyy' // --> Show german date in date picker, need to cast to js date after (open)

export const store = new Vuex.Store({
    state: {
        hzb: '',
        selectedLocation: '',
        title: '',
        firstName: '',
        lastName: '',
        pob: '',
        dob: '',
        gender: '',
        nationality: '',
        address: '',
        city: '',
        postalCode: '',
        phone: '',
        mail: '',
        country: '',
        course: '',
        germanSkill: '',
        langCourse: '',
        addressAdd: '',
        addressComment: ''
    },

    mutations: {
        setValue(state, obj) {
            Reflect.set(state, obj.prop, obj.value);
        }
    }
});

new Vue({
    render: h => h(App)
}).$mount("#app");