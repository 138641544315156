<template>
  <div class="container" style=" padding:20px; background-color:#E6E6E6;">
    <h1>
      <b>Weitere Informationen</b>
    </h1>

    <div class="field is-horizontal">
      <div class="field-body">
        <div class="field">
          <label class="label">Sprachkenntnisse in Deutsch</label>
          <div class="select">
            <select v-model="germanSkill">
              <option>Keine</option>
              <option>A1</option>
              <option>A2</option>
              <option>B1</option>
              <option>B2</option>
              <option>C1</option>
            </select>
          </div>
          <!-- <div class="error" v-if="!$v.germanSkill.required">Auswahl wird benötigt</div> -->
        </div>
        <div class="control">
          <label class="label">Möchten Sie einen Deutschkurs besuchen?</label>
          <label class="radio">
            <input type="radio" name="true" value="true" v-model="langCourse" />
            <b>Ja</b>
          </label>
          <label class="radio">
            <input type="radio" name="false" value="false" v-model="langCourse" />
            <b>Nein</b>
          </label>
          <!-- <div class="error" v-if="!$v.langCourse.required">Auswahl wird benötigt</div> -->
        </div>
      </div>
    </div>
    <div class="field is-horizontal">
      <div class="field-body">
        <div class="field">
          <label class="label">Was möchten Sie an der TU Clausthal studieren?</label>
          <div class="select is-fullwidth">
            <select v-model="course">
              <option v-for="c in courses" v-bind:key="c.id" v-bind:value="c.id">{{ c.text }}</option>
            </select>
          </div>
          <!-- <div class="error" v-if="!$v.course.required">Studiengang wird benötigt</div> -->
        </div>
      </div>
    </div>

    <div class="field is-grouped">
      <div class="buttons">
        <div class="control" style="margin-bottom: 1rem;">
          <button class="button is-link" v-on:click="back" style="margin-bottom:0rem">Zurück</button>
        </div>
      </div>
    </div>
    <div class="field is-grouped">
      <div class="buttons" style="margin-top: 0rem;margin-bottom: -0.5rem;">
        <div class="control">
          <button class="button is-link" v-on:click="next">
            Registrierung abschließen
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import axios from "axios";
import { store } from "../main";

export default {
  name: "Other",

  data() {
    return {
      //course: "",
      courses: [],
      errors: [],
      //germanSkill: "A1",
      //langCourse: "false"
    };
  },

  validations: {
    langCourse: {
      required
    },
    course: {
      required
    },
    germanSkill: {
      required
    }
  },

  async created() {
    try {
      //const response = await axios.get(`http://localhost:51427/api/courses`);
      let apiUrl = process.env.VUE_APP_API_URL
      if (apiUrl === undefined) {
        apiUrl = "http://localhost:5000/"
      }
      const response = await axios.get(`${apiUrl}api/courses`);
      this.courses = response.data.courses;
      if (store.state.course === '') {
        this.course = this.courses[0].id;
      } else {
        this.course = store.state.course;
      }
      if (store.state.germanSkill === '') {
        this.germanSkill = "A1";
      } else {
        this.germanSkill = store.state.germanSkill;
      }
      if (store.state.langCourse === '') {
       this.langCourse = "false"; 
      } else {
        this.langCourse = store.state.langCourse;
      }
      
    } catch (e) {
      this.errors.push(e);
    }
  },

   computed: {
    course: {
      get() {
        return store.state.course;
      },
      set(value) {
        store.commit("setValue", { prop: "course", value: value });
      }
    },
    germanSkill: {
      get() {
        return store.state.germanSkill;
      },
      set(value) {
        store.commit("setValue", { prop: "germanSkill", value: value });
      }
    },
    langCourse: {
      get() {
        return store.state.langCourse;
      },
      set(value) {
        store.commit("setValue", { prop: "langCourse", value: value });
      }
    },
   },

  methods: {
    next: function() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        // do your submit logic here
        this.submitStatus = "OK";
        this.$emit("interface", "Send", {
          germanSkill: this.germanSkill,
          course: this.course,
          langCourse: this.langCourse
        });
      }
    },
    back: function() {
      this.$emit("interface", "Contact");
    }
  }
};
</script>
