<template>
  <div class="container" style=" padding:20px; background-color:#E6E6E6">
    <h1>
      <b>Kontaktinformationen</b>
    </h1>

    <div class="field is-horizontal">
      <div class="field-body">
        <div class="phoneNumber" style="margin-right: 0.75rem;">
          <label class="label">Telefonnummer</label>
          <p class="control is-expanded">
            <input class="input" type="text" placeholder="Telefonnummer" v-model="phone" />
          </p>
        </div>
        <div class="field">
          <label class="label">E-Mail-Adresse</label>
          <p class="control is-expanded form-group">
            <input class="input"
                  :class="{ 'is-danger': $v.mail.$error }" 
                  type="text" placeholder="E-Mail-Adresse" v-model="mail" />
          </p>
          <!-- <div class="error" v-if="!$v.mail.required">E-Mail-Adresse wird benötigt</div> -->
        </div>
      </div>
    </div>

    <div class="field is-grouped">
      <div class="buttons">
        <div class="control">
          <button class="button is-link" v-on:click="back">Zurück</button>
          <button class="button is-link" v-on:click="next">Weiter</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { required, email } from "vuelidate/lib/validators";
import { store } from "../main";

export default {
  name: "Contact",

  data() {
    return {
    };
  },

  validations: {
    phone: {
      required
    },
    mail: {
      required,
      email
    },
  },

  async created() {

  },

  computed: {
    phone: {
      get() {
        return store.state.phone;
      },
      set(value) {
        store.commit("setValue", { prop: "phone", value: value });
      }
    },
    mail: {
      get() {
        return store.state.mail;
      },
      set(value) {
        store.commit("setValue", { prop: "mail", value: value });
      }
    },
  },

  methods: {
    next: function() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        // do your submit logic here
        this.submitStatus = "OK";
        this.$emit("interface", "Other", {
          phone: this.phone,
          mail: this.mail,
        });
      }
    },
    back: function() {
      this.$emit("interface", "Personal");
    }
  }
};
</script>
