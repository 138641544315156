
<template>
  <div class="container is-12" style="padding:20px; background-color:#E6E6E6;">
    <h1>
      <b>Persönliche Informationen</b>
    </h1>
    <form class="form" @submit.prevent="next">
      <div class="field-body">
        <div class="field" style="margin-right:1rem">
          <label class="label">Titel</label>
          <p class="control is-expanded form-group">
            <input
              class="input"
              type="text"
              placeholder="Titel"
              v-model="title"
            />
          </p>

        </div>
        <div class="field">
          <label class="label">Vorname</label>
          <p class="control is-expanded form-group">
            <input
              class="input"
              :class="{ 'is-danger': $v.firstname.$error }"
              type="text"
              placeholder="Vorname"
              v-model="firstname"
            />
          </p>

        </div>
        <div class="field">
          <label class="label">Nachname</label>
          <p class="control is-expanded">
            <input 
              :class="{ 'is-danger': $v.lastname.$error }"
              class="input" 
              type="text" 
              placeholder="Nachname" 
              v-model="lastname" 
            />
          </p>
        </div>
      </div>

        <div class="field-body" style="margin-top:24px; margin-bottom:24px">
          <div class="field">
            <label class="label">Geburtsort</label>
            <p class="control is-expanded">
              <input class="input" 
                    :class="{ 'is-danger': $v.pob.$error }" 
                    type="text" placeholder="Geburtsort" v-model="pob" />
            </p>
          </div>
          <div class="field">
            <label class="label">Geburtstag</label>
            <div style="background-color:white; max-height: 40px">
            <p class="control is-expanded">
              <md-datepicker v-model="dob" style="margin:0 auto; padding-top:4px; height:40px;" />
            </p>
            </div>
          </div>
          <div class="field">
            <label class="label">Geschlecht</label>
            <div class="control">
              <label class="radio">
                <input
                  type="radio"
                  name="male"
                  value="m"
                  v-model="gender"
                  style="margin-left:0.5rem"
                />
                <b>männlich</b>
              </label>
              <label class="radio">
                <input type="radio" name="female" value="w" v-model="gender" />
                <b>weiblich</b>
              </label>
              <label class="radio">
                <input type="radio" name="divers" value="d" v-model="gender" />
                <b>divers</b>
              </label>
            </div>
          </div>
        </div>

      <div class="field is-horizontal">
        <div class="field-body">
          <div class="field">
            <label class="label">Nationalität</label>
            <div class="select is-fullwidth">
              <select v-model="nationality" style>
                <option
                  v-for="n in nationalities"
                  v-bind:key="n.uniqueName"
                  v-bind:value="n.uniqueName"
                >{{ n.text }}</option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <div class="field is-grouped">
        <div class="buttons">
          <div class="control">
            <button class="button is-link" v-on:click="back">Zurück</button>
            <button class="button is-link" v-on:click="next">Weiter</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
export function datecheck(value) {
      if (moment(value).isBetween("1900-01-01", moment()))
      {
        return true;
      
      }
      return false;
    };

import axios from "axios";
import { required } from "vuelidate/lib/validators";
import { store } from "../main";
import moment from "moment";  

export default {
  name: "Personal",

  data() {
    let dateFormat = this.$material.locale.dateFormat || 'yyyy-MM-dd'
    let now = new Date()
    return {
      nationalities: [],
      errors: [],
      submitStatus: "ERROR",
      name: "",
    };
  },

  validations: {
    firstname: {
      required
    },
    lastname: {
      required
    },
    pob: {
      required
    },
    dob: {
      required,
      datecheck
    },
    nationality: {
      required
    },
    gender: {
      required
    }
  },
  

  async created() {
    try {
      //const response = await axios.get(`http://localhost:51427/api/nationalities`);
      let apiUrl = process.env.VUE_APP_API_URL
      if (apiUrl === undefined) {
        apiUrl = "http://localhost:5000/"
      }
      const response = await axios.get(
        `${apiUrl}api/nationalities`
      );

      if (store.state.nationality != "") {
        this.nationalities = response.data.nationalities;
        this.nationality = store.state.nationality;
      }
      else {
        this.nationalities = response.data.nationalities;
        this.nationality = this.nationalities[0].uniqueName;
      }

    } catch (e) {
      this.errors.push(e);
    }
  },

  computed: {
    firstname: {
      get() {
        return store.state.firstName;
      },
      set(value) {
        store.commit("setValue", { prop: "firstName", value: value });
      }
    },
    lastname: {
      get() {
        return store.state.lastName;
      },
      set(value) {
        store.commit("setValue", { prop: "lastName", value: value });
      }
    },
    pob: {
      get() {
        return store.state.pob;
      },
      set(value) {
        store.commit("setValue", { prop: "pob", value: value });
      }
    },
     title: {
      get() {
        return store.state.title;
      },
      set(value) {
        store.commit("setValue", { prop: "title", value: value });
      }
    },
    dob: {
      get() {
        return store.state.dob;
      },
      set(value) {
        store.commit("setValue", { prop: "dob", value: value });
      }
    },
     gender: {
      get() {
        return store.state.gender;
      },
      set(value) {
        store.commit("setValue", { prop: "gender", value: value });
      }
    },
    nationality: {
      get() {
        return store.state.nationality;
      },
      set(value) {
        store.commit("setValue", { prop: "nationality", value: value });
      }
    },
    dateFormat () {
        return this.$material.locale.dateFormat || 'yyyy-MM-dd'
      },
  },

  methods: {
    next: function() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        // do your submit logic here
        this.submitStatus = "OK";
        this.$emit("interface", "Contact", {
          firstname: this.firstname,
          lastname: this.lastname,
          pob: this.pob,
          dob: this.dob,
          nationality: this.nationality,
          gender: this.gender,
          title: this.title
        });
      }
    },
    disablebutton: function() {
      return true;
    },
    back: function() {
      this.$emit("interface", "Location");
    }
  }
};
</script>
