<template>
  <div class="container" style=" padding:20px; background-color:#E6E6E6;">
    <h1>
      <b>Welche Hochschulzugangsberechtigung (HZB) haben Sie?</b>
    </h1>
    <div class="field is-horizontal">
      <div class="field-body">
        <div class="field has-text-centered">
          <div class="select">
            <select v-model="selectedHzb">
              <option v-for="hzb of hzbs" v-bind:key="hzb.text">{{ hzb.text }}</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="field is-grouped">
      <div class="buttons">
        <div class="control">
          <button class="button is-link" v-on:click="next">Weiter</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { store } from "../main";

export default {
  name: "HZB",

  data() {
    return {
      hzbs: [],
      errors: []
    };
  },

  computed: {
    selectedHzb: {
      get() {
        return store.state.hzb;
      },
      set(value) {
        store.commit("setValue", { prop: "hzb", value: value });
      }
    }
  },

  async created() {
    try {
      //const response = await axios.get(`http://localhost:51427/api/hzbs`);
      let apiUrl = process.env.VUE_APP_API_URL
      if (apiUrl === undefined) {
        apiUrl = "http://localhost:5000/"
      }
      const response = await axios.get(`${apiUrl}api/hzbs`);
      
      if (store.state.hzb != "") {
        this.hzbs = response.data.hzbs;
        this.selectedHzb = store.state.hzb;
      }
      else {
        this.hzbs = response.data.hzbs;
        this.selectedHzb = this.hzbs[0].text;
      }
      
    } catch (e) {
      this.errors.push(e);
    }
  },

  methods: {
    next: function() {
      this.$emit("interface", "Location", this.selectedHzb);
    }
  }
};
</script>
