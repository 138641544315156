<template>
  <div class="container" style="padding: 20px; background-color: #e6e6e6">
    <h1>
      <b>Leben Sie derzeit in Deutschland?</b>
    </h1>
    <div class="control" style="text-align: center; font-size: 1.5rem">
      <label class="text">
        <input
          type="radio"
          name="true"
          value="true"
          v-model="selectedLocation"
        />
        <b>Ja</b>
      </label>
      <label class="text" style="margin-left: 1rem">
        <input
          type="radio"
          name="false"
          value="false"
          v-model="selectedLocation"
        />
        <b>Nein</b>
      </label>
    </div>

    <div class="field is-grouped">
      <div class="buttons">
        <div class="control">
          <button class="button is-link" v-on:click="back">Zurück</button>
          <button
            :disabled="selectedLocation != 'false'"
            class="button is-link"
            v-on:click="next"
          >
            Weiter
          </button>
        </div>
      </div>
    </div>
    <div
      :style="{
        visibility: selectedLocation != 'false' ? 'visible' : 'hidden',
      }"
      style="text-align: center"
    >
      <p>
        <b>
          Sollten Sie sich schon in Deutschland befinden, warten Sie bitte bis das Bewerbungsportal für die
          Aufnahme eines Studiums im Sommersemester 2025 an der TU Clausthal freigeschaltet ist.
        </b>
      </p>
      <p>
        <a
          href="https://tuc.hispro.de/qisserver/pages/cs/sys/portal/hisinoneStartPage.faces?chco=y"
          target="_blank"
          >Online Bewerbung</a
        >
      </p>
    </div>
  </div>
</template>

<script>
import { store } from "../main";

export default {
  name: "Location",

  data() {
    return {};
  },

  computed: {
    selectedLocation: {
      get() {
        return store.state.selectedLocation;
      },
      set(value) {
        store.commit("setValue", { prop: "selectedLocation", value: value });
      },
    },
  },

  methods: {
    next: function () {
      this.$emit("interface", "Personal", this.selectedLocation === "true");
    },
    back: function () {
      this.$emit("interface", "HZB");
    },
  },
};
</script>
