<style>
 .bg-img {
  background-image: url("./assets/bg.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-color: #999;
}

footer {
  position: fixed;
  bottom: 0px;
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;
  text-align: center;
  color: #808080;
  font-size: 1.2em;
  background-color: #E6E6E6;
}
.footer {
  background-color: #E6E6E6;
}

.buttons {
  text-align: center;
  margin: 0 auto;
  margin-top: 1rem;
}
.button {
  font-size: 1.2rem;
}
h1 {
  text-align: center;
  font-size: 1.5em;
  padding-bottom: 1rem;
}
.md-field > .md-icon::after {
  height: 0px !important;
}
.md-field::after {
  height: 0px !important;
}
.md-field .md-input-action{
  position:static !important;
}
.md-button::before {
  left:auto !important;
}
:root {
   --md-theme-default-primary:#008C4F !important;
}
::selection {
  background: #008C4F !important;
}
</style>

<template>
  <div class="bg-img">
    <section class="section" style="width:100%; height:100vh;padding:0px;">
      <section class="hero" style="padding:0px;width:100vw;background-color:#E6E6E6;">
        <div class="hero-body" style="padding:0px;padding-bottom:0rem;width:100vw">
          <img alt="TU logo" src="./assets/Logo_TUC.png" style="width:20rem" />
        </div>
      </section>
        <div class="columns is-desktop  is-vcentered is-centered" style="height: 93vh;">
          <div class="column is-half-desktop is-text-centered">
            <component :is="dynamicComponent" @interface="onNext"></component>
            <div id="errorMsg" class="container" style="background-color:#E6E6E6; color: red; text-align:center;"></div>
            <div id="successMsg" class="container" style="background-color:#E6E6E6; color: green; text-align:center;"></div>
          </div>
        </div>
      <footer
        class="footer"
        style="padding-bottom: 1rem; padding-top: 1rem; padding-left: 0px; padding-right: 0px;"
      >
        <div class="content has-text-centered">
          <p style>©TU-Clausthal</p>
        </div>
      </footer>
    </section>
  </div>
</template>

<script>
import Start from "./components/Start";
import HZB from "./components/HZB";
import Location from "./components/Location";
import Personal from "./components/Personal";
import Contact from "./components/Contact";
import Other from "./components/Other";
import Complete from "./components/Complete";

import axios from "axios";
import { store } from "./main";

export default {
  name: "app",
  components: {
    Start,
    HZB,
    Location,
    Personal,
    Contact,
    Other,
    Complete
  },

  data: function() {
    return {
      nextComp: "Start",
      registration: {}
    };
  },

  computed: {
    dynamicComponent() {
      return this.nextComp;
    }
  },
  methods: {
    onNext(c, data) {
      if (c === "HZB") {
        this.nextComp = "HZB";
        return;
      }
      if (c === "Contact" && data === undefined) {
        this.nextComp = "Contact";
        return;
      }
      if (c === "Location") {
        this.registration.hzb = store.state.hzb;
      }
      if (c === "Personal") {
        this.registration.isCurrentCityInGermany = store.state.selectedLocation === 'true';
      }
      if (c === "Contact") {
        this.registration.lastName = data.lastname;
        this.registration.firstName = data.firstname;
        this.registration.dob = data.dob;
        this.registration.pob = data.pob;
        this.registration.nationality = data.nationality;
        this.registration.gender = data.gender;
        this.registration.title = data.title;
      }
      if (c === "Other") {
        this.registration.street = data.address;
        this.registration.postalCode = data.postalCode;
        this.registration.city = data.city;
        this.registration.mail = data.mail;
        this.registration.phone = data.phone;
        this.registration.country = data.country;
        this.registration.addressAddition = data.addressAdd;
        this.registration.addressComment = data.addressComment;
      }
      if (c === "Send") {
        this.registration.degreeProgramm = data.course;
        this.registration.germanLangSkill = data.germanSkill;
        this.registration.isInterestedInLangCourse = data.langCourse === 'true';
        this.sendData();
        this.nextComp = "Complete";
        return;
      }
      this.nextComp = c;
    },

    async sendData() {
      try {
        let apiUrl = process.env.VUE_APP_API_URL
        if (apiUrl === undefined) {
          apiUrl = "http://localhost:5000/"
        }
        // 
        let response = await axios({
          url: `${apiUrl}api/enroll`,
          method: 'POST',
          responseType: 'blob',
          data: this.registration
        });
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', 'Registrierung.pdf');
        document.body.appendChild(fileLink);
        fileLink.click();
        var message = document.getElementById("successMsg");
        message.innerHTML = "Download erfolgreich. Bitte prüfen Sie Ihren Download-Ordner.";
        
      } catch (error) {
        var element = document.getElementById("errorMsg");
        element.innerHTML = "Fehler beim Finalisieren der Registrierung, bitte die eigegebene Daten überprüfen.";
      }
    }
  }
};
</script>
